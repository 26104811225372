<template>
  <div>
    <portal to="page-top-title">Api Provider #{{ id }}</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'apis-providers-edit', params: { id: id } }" icon="fa-edit" text>Edit</btn>
    </portal>

    <box-single :url="`/apis/providers/${id}`" data-prop="provider" resp-prop="data" loader>
      <template slot="content">
        <row>
          <column-info title="Name">{{ provider.name }}</column-info>
          <column-info title="Active">
            <badge-active :value="provider.active"/>
          </column-info>
          <column-info title="Created At">{{ provider.created_at }}</column-info>
          <column-info title="Updated At">{{ provider.updated_at }}</column-info>
        </row>
        <row>
          <column-info title="Credentials">
            <template v-for="(cred, i) in provider.credentials">
              <span :key="`provider-cred-${i}-comma`" v-if="i !== 0">, </span>
              <code :key="`provider-cred-${i}`">{{ cred }}</code>
            </template>
          </column-info>
          <column-info title="Apis">
            <badge title="Total">{{ provider.apis_count_total }}</badge>
            <badge title="Active" color="green">{{ provider.apis_count_active }}</badge>
            <badge title="Inactive" color="red">{{ provider.apis_count_inactive }}</badge>
          </column-info>
        </row>
      </template>
    </box-single>

    <box-table title="Apis" url="/apis" :url-query="{ provider_id: id }" data-prop="apis" loader>
      <template slot="box-tools-right">
        <btn :route="{ name: 'apis-new', query: { provider: id } }" size="xs" icon="fa-plus" text>Add New</btn>
      </template>
      <template slot="table-header">
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th class="text-center">Sending Rate</th>
          <th class="text-center">Active</th>
          <th class="text-right">Created At</th>
          <th class="text-right">Updated At</th>
          <th class="text-right"></th>
        </tr>
      </template>
      <template slot="table-body">
        <table-row v-for="api in apis" :key="`apis-tr-${api.id}`" :value="api">
          <td>{{ api.id }}</td>
          <td>{{ api.name }}</td>
          <td class="text-center">
            <badge title="TPS">{{ api.sending_rate }}</badge>
          </td>
          <td class="text-center">
            <badge-active :value="api.active"/>
          </td>
          <td class="text-right">{{ api.created_at }}</td>
          <td class="text-right">{{ api.updated_at }}</td>
          <td class="text-right">
            <btn :route="{ name: 'apis-edit', params: { id: api.id } }" icon="fa-edit">Edit</btn>
            <btn :route="{ name: 'apis-single', params: { id: api.id } }" color="info" icon="fa-eye">Show</btn>
          </td>
        </table-row>
      </template>
    </box-table>
  </div>
</template>

<script>
import BoxSingle from '@/views/components/data/BoxSingle'
import BoxTable from '@/views/components/data/BoxTable'
import BadgeActive from '@/views/components/simple/BadgeActive'
import Badge from '@/views/components/simple/Badge'
import Btn from '@/views/components/simple/Btn'

export default {
  name: 'ApisProvidersSingle',
  metaInfo () {
    return { title: 'Apis | Provider #' + this.id }
  },
  components: {
    BoxSingle,
    BoxTable,
    BadgeActive,
    Badge,
    Btn
  },
  computed: {
    id () { return this.$route.params.id }
  },
  data () {
    return {
      provider: {},
      apis: []
    }
  }
}
</script>
